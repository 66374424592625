<template>
  <div class="ml-8">
    <div class="">
      <div>
        <h1 class="appointments text-justify text-xl mt-12 text-appointments">
          User project roles
        </h1>
      </div>
      <div class="mt-10 md:flex justify-between">
        <div class="flex place-content-center">
          <div class="pt-2 mr-2">Allow analysts to complete projects</div>
          <SwitchInput
            id="switch"
            :checked="canAnalystsCompleteProjects"
            @change="toggleCanAnalystsCompleteProjects"
          />
        </div>

        <div class="flex place-content-center">
          <div class="pt-2 mr-2">Allow analysts to view all projects</div>
          <SwitchInput
            id="switch1"
            :checked="canAnalystsViewAllProjects"
            @change="toggleCanAnalystsViewAllProjects"
          />
        </div>
        <Search label="Search " class="w-50 mr-10" @input="searchUsers" />
      </div>
    </div>

    <div v-if="elementsAvailble">
      <table>
        <thead>
          <tr
            class="flex flex-col flex-no wrap table-row rounded-l-lg rounded-none mb-2 mb-0"
          >
            <th v-if="fields['userDetails']" class="p-3 text-left">
              <span class="flex text-table text-xs">User details</span>
            </th>
            <th v-if="fields['role']" class="p-3 text-left">
              <span class="flex sm:flex text-table text-xs">System Role</span>
            </th>
            <th v-if="fields['status']" class="p-3 text-left pl-8">
              <span class="flex text-table text-xs">Projects Role</span>
            </th>

            <th class="p-3 text-left text-table text-xs flex justify-between">
              <!-- <p class="md:-ml-14 ">Actions</p> -->
              <AppointmentActionButtons
                @deleted="removedDeletedItems"
                v-if="selectedUsers.length"
                :isSecretary="true"
                :showIcon="true"
                :deleteFill="`${selectedUsers.length ? '#FB2222' : '#B0B0B0'}`"
                :id="selectedUsers"
                ACTION="USER"
              />
            </th>
          </tr>
        </thead>
        <tbody class="sm:flex-1 sm:flex-none">
          <tr
            v-for="user in users"
            :key="user.id"
            class="sm:flex sm:flex-col sm:flex-no sm:wrap sm:table-row sm:mb-2 sm:mb-0 h-80 main-header sm:header sm:shadow-lg"
          >
            <td
              v-if="fields['userDetails']"
              class="p-3 pl-6 flex lg:-ml-4 text-left"
            >
              <div class="flex">
                <Avatar
                  :fullname="`${user.firstName}` + ' ' + `${user.lastName}`"
                  :size="27"
                  class="mt-1 sm:mt-2"
                  color="#1679A8"
                ></Avatar>
                <!-- <img :src="`${user.profile}`" alt=""  /> -->
                <p
                  class="ml-2 text-xs sm:ml-5 mt-2 text-xs sm:text-sm sm:text-values title"
                >
                  {{ user.firstName + " " + user.lastName }}
                </p>
              </div>
            </td>
            <td v-if="fields['status']" class="p-3 sm:font-semibold">
              <div class="w-24 sm:h-4 bg-white">
                <span
                  class="sm:text-values text-xs sm:text-sm sm:title text-left"
                  >{{ user.roles[0].name }}</span
                >
              </div>
            </td>
            <td v-if="fields['role']" class="p-3">
              <div class="w-32 sm:h-5 p-0.5 bg-table text-center">
                <span class="sm:text-values text-xs sm:text-sm title">{{
                  userProjectRolesDictionary[user.id] || "No role"
                }}</span>
              </div>
            </td>

            <td class="text-values text-sm p-3 flex">
              <div
                class="w-24 mr-2 rounded text-view cursor-pointer h-7 bg-table pt-1 pb-1.5 text-center"
                @click="
                  (e) => {
                    selectedUser = {
                      id: user.id,
                      name: user.firstName + ' ' + user.lastName,
                      role: userProjectRolesDictionary[user.id],
                      canAccessDocuments: userProjectRolesAccessDictionary[user.id],
                      canAccessMeetings: userProjectRolesAccessDictionary[user.id+'_meetings'],
                      canAccessProjects: userProjectRolesAccessDictionary[user.id+'_projects'],
                    };
                  }
                "
              >
                <span class="view text-xs"
                  >{{
                    userProjectRolesDictionary[user.id] ? "Update" : "Set"
                  }}
                  role</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ElementsNotFound
      v-show="!elementsAvailble"
      :isLoading="loading"
      type="Users"
    />
    <ChangeStatusModal
      v-if="selectedUser"
      class="small-nav"
      @close="selectedUser = null"
      @changeRole="changeRole"
      :user="selectedUser"
    />
  </div>
</template>
<script>
import Button from "/src/components/Listings/Button.vue";
import AppointmentActionButtons from "/src/components/Listings/AppointmentActionButtons.vue";
import Search from "/src/components/searchInput.vue";
import Api from "/src/services/Apis";
import Avatar from "vue-avatar-component";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import SwitchInput from "/src/components/Switch.vue";
import { mapActions } from "vuex";

import ChangeStatusModal from "/src/components/Listings/ChangeStatusModal";
export default {
  name: "UserListing",
  components: {
    Button,
    AppointmentActionButtons,
    Search,
    Avatar,
    ElementsNotFound,
    ChangeStatusModal,
    SwitchInput,
  },
  data() {
    return {
      toShow: false,
      checkDisabled: false,
      numberOfChecks: 0,
      date: [],
      users: [],
      userProjectRoles: [],
      loading: true,
      elementsAvailble: true,
      selectedUsers: [],
      selectedUser: null,
      fields: [],
      canAnalystsCompleteProjects: false,
      canAnalystsViewAllProjects: false,
    };
  },
  methods: {
    ...mapActions({ getFields: "settings/getAllowedFields" }),
    changeRole(obj) {
      const { userId, role, canAccessDocuments, canAccessMeetings, canAccessProjects } = obj;
      if (userId && role) {
        Api.create(`projects/role/user/${userId}`, {
          role,
          canAccessDocuments,
          canAccessMeetings,
          canAccessProjects
        }).then((response) => {
          this.userProjectRoles.push(response.data.data);
          this.selectedUser = null;
        });
      }
    },
    toggleCanAnalystsCompleteProjects() {
      Api.update("projects", "toggle-can-analysts-complete-project", {}).then(
        (response) => {
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: "Updated successfully",
            uptime: 3000,
          });
          this.canAnalystsCompleteProjects = response.data.data;
        }
      );
    },
    toggleCanAnalystsViewAllProjects() {
      Api.update("projects", "toggle-can-analysts-access-all-projects", {}).then(
        (response) => {
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: "Updated successfully",
            uptime: 3000,
          });
          this.canAnalystsViewAllProjects = response.data.data;
        }
      );
    },
    removedDeletedItems() {
      this.users = this.users.filter((e) => e.id != this.selectedUsers[0]);
      this.selectedUsers = [];
      this.$store.dispatch("notification/SET_NOTIFICATION", {
        msg: "User deleted sucessfully",
        uptime: 3000,
      });
    },
    async getUsers() {
      Api.get("projects/can-analysts-complete-project").then((response) => {
        this.canAnalystsCompleteProjects = response.data.data;
      });
            Api.get("projects/can-analysts-access-all-projects").then((response) => {
        this.canAnalystsViewAllProjects = response.data.data;
      });
      Api.get("users").then((response) => {
        this.users = response.data.content;
        if (!this.users.length) {
          this.elementsAvailble = false;
        } else {
          this.elementsAvailble = true;
        }

        Api.get("projects/userRoles").then((response) => {
          this.userProjectRoles = response.data.data;
          this.loading = false;
        });

        this.loading = false;
      });
    },
    async searchUsers(searchKey) {
      if (searchKey === "") {
        this.getUsers();
      } else {
        await Api.get("users/search/" + searchKey)
          .then((resp) => {
            this.users = resp.data;
            if (!this.users.length) {
              this.elementsAvailble = false;
            } else {
              this.elementsAvailble = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  computed: {
    // disableBtn() {
    //   if (this.selectedUsers.length > 0) {
    //     return (this.checkDisabled = false);
    //   } else if (this.selectedUsers.length <= 0) {
    //     return (this.checkDisabled = true);
    //   }
    // },
    userProjectRolesDictionary() {
      let dictionary = {};
      for (let userProjectRole of this.userProjectRoles) {
        dictionary[userProjectRole.user.id] = userProjectRole.status;
      }
      return dictionary;
    },
    userProjectRolesAccessDictionary() {
      let dictionary = {};
      for (let userProjectRole of this.userProjectRoles) {
        dictionary[userProjectRole.user.id] = userProjectRole.canAccessDocuments;
        dictionary[userProjectRole.user.id + "_meetings"] = userProjectRole.canAccessMeetings;
        dictionary[userProjectRole.user.id + "_projects"] = userProjectRole.canAccessProjects;
      }
      return dictionary;
    },
  },
  async created() {
    this.fields = await this.getFields("users_table");
    this.getUsers();
  },
};
</script>
<style scoped>
table {
  width: 97%;
  border-collapse: collapse;
}
.appointments {
  font-weight: 600;
  font-size: 40px;
}
.search-area {
  background: url("/icons/search.svg");
  background-position: 10rem 8px;
  background-repeat: no-repeat;
  background-color: white;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.title {
  font-weight: 600;
}
.header {
  background: white;
  height: 3.5rem;
}
.names {
  margin: 0 3rem 0 -8rem;
}

@media only screen and (max-width: 760px),
  (min-device-width: 250px) and (max-device-width: 640px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    background: white;
    width: 20rem;
    margin: 1rem 0 0 0;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%);
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 12px;
    text-align: justify;
    color: #000000;
  }

  td:nth-of-type(2):before {
    content: "User details";
  }
  td:nth-of-type(3):before {
    content: "Email";
  }
  td:nth-of-type(4):before {
    content: "Role";
  }
  td:nth-of-type(5):before {
    content: "Status ";
  }
  td:nth-of-type(6):before {
    content: "Mobile";
  }
  td:nth-of-type(7):before {
    content: "Actions";
  }
}
</style>
