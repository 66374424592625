<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div :class="`delete-modal ${size}`">
        <div>
          <h1 class="text-base heading">
            Change project role of
            <span class="text-blue-500">{{ user.name }}</span>
          </h1>

          <div class="mx-8 my-5">
            <TextInput
              :label="'Role'"
              :not="true"
              :types="'select'"
              :defaultValue="user.role"
              @input="setRole"
              :options="[
                { id: 'ANALYST', name: 'ANALYST' },
                { id: 'SUPERVISOR', name: 'SUPERVISOR' },
                { id: 'VIEWER', name: 'VIEWER' },
              ]"
            />
          </div>

          <div class="mx-8 my-5 flex">
            <div class="pt-2 mr-2">Allow access to Cabinet Log</div>
            <SwitchInput
              id="switch2"
              :checked="user.canAccessDocuments"
              @change="setCanAccessCabinetLog"
            />
          </div>

          <div class="mx-8 my-5 flex">
            <div class="pt-2 mr-2">Allow access to Meetings</div>
            <SwitchInput
              id="switch3"
              :checked="user.canAccessMeetings"
              @change="setCanAccessMeetings"
            />
          </div>

          <div class="mx-8 my-5 flex">
            <div class="pt-2 mr-2">Allow access to Projects </div>
            <SwitchInput
              id="switch4"
              :checked="user.canAccessProjects"
              @change="setCanAccessProjects"
            />
          </div>

          <div class="block sm:flex mx-8">
            <div>
              <button
                :style="`backgroundColor: ${abort}`"
                class="rounded w-40 mt-8 ml-8 text-base outline-none font-extralight"
                @click="close()"
              >
                Abort
              </button>
            </div>
            <div>
              <button
                :style="`backgroundColor:${deleteBg} `"
                class="rounded w-40 mt-8 ml-8 text-base outline-none font-extralight"
                @click="change()"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import TextInput from "../form/TextInput.vue";
import SwitchInput from "/src/components/Switch.vue";
export default {
  name: "DeleteModal",
  components: {
    TextInput,
    SwitchInput,
  },
  data() {
    return {
      msg: "",
      hasError: false,
      abort: "#B6C0C9",
      deleteBg: "#28A4E2",
    };
  },
  props: {
    size: {
      type: String,
      default: "big",
    },
    user: {
      type: Object,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setRole(value) {
      this.user.role = value;
    },
    setCanAccessCabinetLog(value) {
      this.user.canAccessDocuments = !this.user.canAccessDocuments;
    },
    setCanAccessMeetings(value) {
      this.user.canAccessMeetings = !this.user.canAccessMeetings;
    },
    setCanAccessProjects(value) {
      this.user.canAccessProjects = !this.user.canAccessProjects;
    },
    change() {
      this.$emit("changeRole", {
        userId: this.user.id,
        role: this.user.role,
        canAccessDocuments: this.user.canAccessDocuments,
        canAccessMeetings: this.user.canAccessMeetings,
        canAccessProjects: this.user.canAccessProjects,
      });
    },
  },
};
</script>
